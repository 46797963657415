.hero-image {
    position: relative;
    background-position: center;
    background-color: black;
    display: flex;
    min-height: calc(310px + var(--default-header-height));
    height: calc(42.2vh + var(--default-header-height));

    .overlay {
        position: absolute;
        max-width: var(--slim-max-width);
        padding: 0 var(--default-desktop-padding);
        margin: 0 auto;
        top: 110px;
        left: 0;
        right: 0;
        width: 100%;
        height: 65%;
        z-index: 3;

        h1 {
            font-size: 46px;
            display: block;
            color: #fff;
            text-shadow: 0 0 0.6em black, 0 0 0.1em black;

            @media screen and (max-width: 992px) {
                font-size: 32px;
            }
        }

        .icon {
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFF;
            width: 160px;
            height: 60px;
            border-radius: 4px;

            img {
                width: 140px;
                height: 50px;
                position: absolute;
                object-fit: contain;
            }
        }

        @media screen and (max-width: 992px) {
            padding-top: 0;
            top: 80px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 var(--default-mobile-padding);
        }
    }

    .image {
        height: 100%;
        width: 100vw;
        object-fit: cover;
        opacity: 95%;
        min-height: 310px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 90%;
        width: 100%;
        top: 40px;
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        padding-top: var(--default-header-height);
        z-index: 2;
    }

    @media screen and (max-width: 992px) {
        height: 100%;
        padding-top: var(--default-mobile-header-height);
    }
}

.button {
  background-color: var(--color-white);
  color: var(--color-coal);
  font-size: 18px;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 100px;
  cursor: pointer;
  border: none;

  &:hover {
    background: var(--color-coal);
    color: var(--color-white);
  }
}

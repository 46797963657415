.cover {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 91px;
    background: linear-gradient(180deg, rgba(0,0,0,1) 21%, rgba(255,255,255,0) 100%);
    z-index: 4;

    &.hidden {
        display: none;
    }

    @media screen and (max-width: 992px) {
        height: var(--default-mobile-header-height);
    }
}

.mainHeader {
    position: fixed;
    background: rgba(0,0,0,0.9);
    top: 0;
    width: 100%;
    z-index: 10;

    .container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        max-width: var(--default-max-width);
        min-height: 91px;
        padding: 20px var(--default-desktop-padding);
        margin: 0 auto;

        & > a {
            font-size: 0;

            @media screen and (max-width: 992px) {
                img {
                    height: 34px;
                }
            }
        }

        @media screen and (max-width: 992px) {
            padding: 16px var(--default-mobile-padding);
            min-height: var(--default-mobile-header-height);
        }
    }

    a.logotype {
        display: block;

        img {
            display: block;
        }
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;

        .hidden {
            display: none;
        }

        a {
            color: white;
            font-size: 18px;
            font-weight: 300;
            padding: 6px 15px 10px;
            text-decoration: none;
            transition: color 250ms;

            &:hover {
                color: var(--color-sand) !important;
            }

            &.active {
                color: var(--color-sand) !important;
            }

            &.solid {
                color: var(--color-black);
                background-color: var(--color-sand-light);
                border-radius: 50px;
                margin-left: 15px;
                margin-right: 15px;
                transition: background-color 250ms;

                &.active {
                    color: var(--color-black) !important;
                    background-color: var(--color-sand);
                }

                &:hover {
                    color: var(--color-black) !important;
                    background-color: var(--color-sand);
                }

                &:last-child {
                    margin-left: 15px;
                    margin-right: 0;
                }

                &:first-child {
                    margin-left: 0;
                    margin-right: 15px;
                }
            }
        }
    }

    button {
        all: unset;
    }

    .close {
        color: var(--color-white);
        font-size: 40px;
        position: absolute;
        right: 32px;
        top: 38px;
    }

    .open {
        color: var(--color-white);
        font-size: 40px;
    }

    .grid {
        display: grid;
    }

    .mobile-wrapper {
        display: none;

        @media screen and (max-width: 992px) {
            display: block;
        }
    }

    .desktop-wrapper {
        display: none;

        @media screen and (min-width: 993px) {
            display: block;
        }
    }

    .mobile-drawer {
        position: absolute;
        align-content: flex-start;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: var(--color-black);
        padding: 8px var(--default-mobile-padding);
        gap: 16px;
        z-index: 20;

        a {
            font-size: 32px;
            font-weight: 500;
        }

        .colored-line-break {
            content: '';
            height: 1px;
            background-color: var(--color-coal);
            margin: 0px auto;
            width: 91%;
        }

        .external {
            display: flex;
            font-size: 32px;
            gap: 24px;
            align-items: center;

            svg {
                color: var(--color-sky);
            }
        }
    }
}

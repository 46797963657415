footer.main {
    background: black;
    padding: 117px 88px;
    color: white;

    .container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        max-width: 1190px;
        margin: 0 auto;

        .image {
            max-width: 100%;
            margin-bottom: 32px;
        }

        .left {
            a:hover {
                text-decoration: underline;
            }
        }

        .center {
            align-items: center;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .right {
            align-items: flex-end;
        }

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    @media screen and (max-width: 992px) {
        padding: 60px 24px;
        .container {
            flex-wrap: wrap;

            .left,
            .right {
                flex-basis: 50%;
                margin-bottom: 60px;
            }

            .center {
                flex-basis: 100%;
                order: 3;
            }
        }
    }

    .left {
        display: flex;
        flex-direction: column;

        & > * {
            padding: 8px 16px;
            font-size: 12px;
            font-weight: 300;
            text-transform: uppercase;
            color: var(--color-white);
        }

        a {
            color: var(--link-light);
            font-family: Muli, sans-serif;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
            text-decoration: none;
            text-transform: revert;
        }
    }

    p a {
        color: var(--link-light);
    }

    .social {
        display: inline-flex;
        flex-direction: column;

        & > * {
            padding: 8px 16px;
            font-size: 12px;
            font-weight: 300;
            text-transform: uppercase;
            color: var(--color-white);
        }

        a {
            color: var(--link-light);
            font-family: Muli, sans-serif;
            font-size: 16px;
            font-style: normal;
            line-height: normal;
            text-decoration: none;
            text-transform: revert;
            display: flex;
            align-items: center;
            gap: 8px;

            &:focus {

            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

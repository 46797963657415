.button-blok {
    text-decoration: none;
    padding: 8px 24px;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 300;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 300px) {
        word-break: break-word;
    }
}

.solid {
    background: var(--color-sky-darker);
    color: var(--color-white);
    padding-bottom: 10px;
}

.text {
    background: var(--color-egg-white);
    color: var(--color-sky-darker);
    padding-bottom: 10px;
}

.scheduler-button {
    background-color: #000;
    color: #FFF;
    padding: 18px 64px;
    border-radius: 8px;
    font-size: 26px;
    text-align: center;
    max-height: 75px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        line-height: 26px;
    }

    .description {
        font-size: 12px;
        font-weight: 200;
        text-align: left;

        @media screen and (max-width: 950px) {
            text-align: center;
        }
    }

    @media screen and (max-width: 950px) {
        width: 100%;
        padding: 12px 24px;
    }
}

.icon {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;

    svg {
        position: absolute;
        left: 24px;
        top: 52%;
        transform: translateY(-50%);
        width: 24px;
        height: auto;
    }
}

.close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 27px;
    cursor: pointer;
}

.scheduler {
    margin: 40px 40px 0;
    transform: translateY(-5%);
    min-width: min(800px, 80vw);

    > * {
        margin: 0;
        padding: 0;
        top: 0;

        h2 {
            margin: 0 !important;
        }
    }

    @media screen and (max-width: 700px) {
        margin: 40px 16px 0;
    }
}

.wrapper {
  padding: 40px;
  border-radius: 24px;
  min-height: 410px;
  display: flex;
  justify-content: center;

  .header {
    display: flex;
    gap: 18px;
  }

  h2 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0;
    flex: 1;
  }

  .close {
    font-size: 27px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  .img {
    border-radius: 60px;
  }

  span {
    color: var(--color-bright-blue);
  }

  @media screen and (max-width: 600px) {
      padding: 20px;
  }
}

.container {
  display: flex;
  gap: 24px;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 40px;
  flex-grow: 1;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      padding-bottom: 32px;
      gap: 16px;
      p {
        margin: 0;
      }
    }
}

.buttonContainer {
  display: flex;
  gap: 24px;

  input {
    flex-grow: 2;
    border-radius: 8px;
    padding: 10px;
    fill: #5a5954;
    background-color: #F8F8F8;
    border: 0;
  }

  input:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    gap: 16px;
    flex-direction: column;
  }
}

.button {
  flex-grow: 1;
  padding: 10px;
  border-radius: 50px;
  border: 1px solid #000000;
  background: #E0F2F7;
  color: #395963;
  cursor: pointer;

  // @todo Add animation
  &:disabled {
    opacity: 0.3;
  }
}

.contactLink {
  background: none;
  border: none;
  padding: 5px;
  font-size: inherit;
  font-family: inherit;
  color: #62615C;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  margin: 24px auto 0;
  cursor: pointer;
}

.emailContainer {
  p {
    margin-top: 47px;
    margin-bottom: 32px;
    max-width: 380px;
  }

  .buttonContainer {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.confirmationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;

  .confirmationContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .confirmationHeader {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.16;
    margin-top: 0;
    margin-bottom: 24px;
  }

  p {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .button {
    max-width: 190px;
    width: 100%;
    margin-bottom: 0;
  }
}
